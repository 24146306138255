<template>
    <div class="news-details-container" id="page-container">
        <navbar-interactive v-bind:defaultBlack="true"></navbar-interactive>
        <div class="news-details-frame">
            <div class="news-details-page-content">
                <div class="news-details-hero-image"></div>
                <div class="news-details-news-container">
                    <div class="news-details-frame13">
                        <span class="news-details-text">
                            隐私政策
                        </span>
                        <div class="content">
                            <p><u style="text-indent: 0em;" data-mce-style="text-indent: 0em;">
                                    <font face="宋体">引言</font>
                                </u></p>
                            <p></p>
                            <p>
                                <font face="宋体">北京素鳞科技产业有限公司（以下简称</font>
                                <font face="宋体">“我们”）非常重视您的隐私和个人信息保护。您在</font>
                                进入到我们的线上展览时，我们可能会收集和使用您的相关信息。我们希望通过本隐私政策向您说明在您参加线上展览时，我们如何收集、使用、储存、共享这些信息，以及我们为您提供的访问、更新、控制和保护这些信息的方式。
                            </p>
                            <p>我们深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任。同时，我们承诺，我们将按业界成熟的安全标准，采取相应的安全措施来保护您的个人信息。</p>
                            <p>
                                <font face="宋体">
                                    请在使用我们的服务前，仔细阅读并了解本隐私政策。如果您不同意本隐私政策的内容，将导致我们无法为您提供完整的服务，同时也请您立即停止使用我们的服务。您使用或继续使用我们提供的服务，则表示您同意我们按照本政策收集、使用、储存和分享、披露、保护您的个人信息。如果您对我们如何收集和处理您的个人信息有任何问题或者疑虑，您可通过本隐私政策
                                </font>
                                <font face="宋体">“如何联系我们”部分的联系方式联系我们。</font>
                            </p>
                            <p></p>
                            <p><u>
                                    <font face="宋体">我们收集哪些信息</font>
                                </u><u></u></p>
                            <p><u></u></p>
                            <p>在您注册参加我们的线上展览时，我们会直接通过您收集您的个人信息。</p>
                            <p>如果您通过网站以访客身份注册我们的线上展览时，我们可能会收集您的精确位置以识别或验证用户身份并用于访问控制目的。</p>
                            <p></p>
                            <p>个人信息的使用方式和目的</p>
                            <p>展会和展览管理目的</p>
                            <p></p>
                            <p align="justify">
                                如果您以演讲嘉宾、赞助商、参展商或参观者的身份参加展会，我们将在管理展会上使用您的个人信息。这些个人信息也能帮助我们开展后勤管理、分析活动，以确保活动顺利进行，并且让我们能够为客户提供更好的体验。
                            </p>
                            <p align="justify">
                                此外，我们在活动中还会拍摄照片和视频，与会者、演讲嘉宾、赞助商或参展商可能会入镜。在适用数据保护法律允许的范围内，如果您作为与会者、赞助商或参展商入镜了我们拍摄的活动照片和视频，我们会将这些照片和视频用于宣传目的。第三方也可以在我们的活动中拍照，例如用
                                <font face="宋体">于记录其参观过的展台或参展商。我们对收集或使用第三方摄像头采</font>集的图像概不负责。</p>
                            <p>如果展会和展览使用参展证扫描技术，我们将在展会的某些进入地点，如VIP
                                区域、研讨会会场、进出区域和其他区域，使用您的个人信息来识别您的身份。此种情况下我们将获得您的某些定位信息。参展证和参展登记二维码无法远程读取。</p>
                            <p>通过参展证或者参展登记二维码扫描获得的个人信息将被用于入场控制、安全和分析参展人员人流量等目的，以改善参展人员和其他人的展会体验。</p>
                            <p>根据我们在您注册和登录过程中向您提供的隐私声明和参观人士条例所述，当您进入虚拟空间、区域或房间（例如展位）时，我们会与赞助商和参展商共享您的个人信息，以便他们与您进行互动。
                            </p>
                            <p>营销</p>
                            <p align="justify">我们可以使用您的个人信息，向您发送简报、优惠产品<font face="宋体">信息或其他营销邮件，让您实时了解您可</font>
                                能感兴趣的我们的发展动态、展会和产品。根据您与我们互动的性质和适用的数据保护法律，您可以主动同意（即选择接收）我们向您发送营销信息，或者我们有权依据您的对该隐私政策的同意向您发送此类信息。您可以在此后选择不接收营销信息，并有权随时改变您的营销偏好。
                            </p>
                            <p align="justify">
                                在某些情况下，如果我们拥有合法依据并且您已提供个人手机号码，我们会根据适用的数据保护法律，出于营销目的向您发送营销短信或打电话。如果我们具备合法依据并且您已提供个人邮政地址，我们还会邮寄包含新产品和活动信息的信件。
                            </p>
                            <p></p>
                            <p>我们保护个人信息的方式</p>
                            <p>我们意识到，保护和管理个人信息十分重要。我们将尽量以最谨慎安全的方式处理您提供的所有个人信息。本节将介绍部分现行安全措施。</p>
                            <p>我们采用多种物理和技术措施保护个人信息安全，防止未经授权访问、使用或披露这些信息。我<font face="宋体">们会将电子数据和数据库存储在安全的计算机系统中，并通过物理和电子</font>手段控制信息访问权</p>
                            <p>限。我们制定了详细的安全和数据保护政策并且为员工提供数据保护培训，要求他们在处理个人信息时必须遵守相关规定。</p>
                            <p></p>
                            <p>我们仅在必要期间或相关法律许可的期间保留个人信息。</p>
                            <p align="justify">
                                如上文所述，我们在使用个人信息的期间保留个人信息。我们可以在停止使用后出于某些合法商业目的继续使用信息。例如，如果您选择不再接收我们的营销信息，我们将仅保留与您有关的有限资料，确保我们满足您不接收信息的要求。此外，为了遵守法律规定，保护或行使我们的合法权利，我们也可以继续保留您的个人信息。
                            </p>
                            <p align="justify">
                                <font face="宋体">在确定适当的个人信息保存期时，我们会考虑个人信息的数量、性质和敏感性，</font>
                                <font face="宋体">未经授权使用或</font>
                                披露您的个人信息可能带来的风险，我们处理您的个人信息的目的和我们是否可以通过其他方式实现这些目的，以及适用的法律要求。在上述保存期届满后，除非获得您的明确同意，我们会删除您的相关个人信息，或者对其实施匿名化。
                            </p>
                            <p></p>
                            <p>联系我们</p>
                            <p>如果您对本隐私政策有任何疑问，请联系：</p>
                            <p>个人信息保护团队</p>
                            <p>
                                <font face="宋体">邮件：</font><a href="mailto:admin@solart.pro"
                                    data-mce-href="mailto:admin@solart.pro">admin@solart.pro</a>
                            </p>
                            <p></p>
                            <p>
                                <font face="Arial">一般情况下，我们将在</font>
                                15个工作日内回复。如果您对我们的回复不满意，特别是我们的信息处理行为损害了您的合法权益，您还可以向网信、电信、公安及工商等监管部门进行投诉或举报。
                            </p>
                            <p></p>
                            <p></p>
                            <p></p>
                        </div>
                    </div>
                </div>
                <div class="news-details-top-navigation-bar">
                    <div class="news-details-top-navigation-bar-left-logo">
                        <img alt="logo6457" src="/logo%201-1500h.png" class="news-details-logo" />
                    </div>
                    <BottomBar />
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import NavbarInteractive from "../components/navbar-interactive";
import BottomBar from "../components/bottomBar.vue";

export default {
    name: "PrivatePolicy",
    components: {
        NavbarInteractive,
        BottomBar,
    },
    data() {
        return {
            newsId: 0,
            newsTitle: "",
            summary: "",
            parsedHTML: "",
            published_time: "",
        };
    },
    metaInfo: {
        title: "隐私政策 - SOLART素鳞科技",
        meta: [
            {
                property: "og:title",
                content: "隐私政策 - SOLART素鳞科技",
            },
        ],
    },
};
</script>
  
<style>
.news-details-container {
    width: 100%;
    display: flex;
    overflow: auto;
    min-height: 100vh;
    align-items: center;
    flex-direction: column;
}

.content p {
    line-height: 1.6;
    margin-bottom: 10px;
}

.news-details-frame {
    gap: 10px;
    width: 100%;
    height: auto;
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    flex-shrink: 0;
    background-color: rgba(26, 26, 26, 1);
}

.news-details-page-content {
    margin-top: 40px;
    gap: 39px;
    width: 100%;
    display: flex;
    flex-grow: 1;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
}

.news-details-hero-image {
    gap: 10px;
    height: auto;
    display: flex;
    padding: 22px 0;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    flex-direction: column;
    background-size: cover;
    justify-content: space-between;
}

.news-details-news-container {
    gap: 20px;
    width: 80%;
    height: auto;
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    min-height: 80vh;
    color: #fff;
}

.news-details-frame13 {
    gap: 40px;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.news-details-frame3 {
    gap: 21px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.news-details-text {
    color: rgba(255, 255, 255, 1);
    width: 75%;
    height: auto;
    font-size: 24px;
    font-style: Bold;
    text-align: center;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 35px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text01 {
    color: rgba(255, 255, 255, 1);
    width: 775px;
    height: auto;
    opacity: 0.8;
    font-size: 16px;
    text-align: center;
    font-family: Noto Sans SC;
    line-height: 25px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text05 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    opacity: 0.6;
    font-size: 16px;
    text-align: center;
    font-family: Noto Sans SC;
    line-height: 25px;
    font-stretch: normal;
    text-decoration: none;
}

.content {
    width: 100%;
}

.imgcontent {
    width: 100%;
    height: 500px;
    max-width: 1002px;
    object-fit: contain;
    border-radius: 10px;
}

.pcontent {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text08 {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.h1title {
    color: rgb(255, 255, 255);
    border-left: 4px solid #016ded;
    padding-left: 10px;
    width: 100%;
    height: auto;
    opacity: 0.8;
    font-size: 16px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 28.8px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text10 {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-placeholder3 {
    width: 100%;
    height: 500px;
    max-width: 1002px;
    object-fit: contain;
    border-radius: 10px;
}

.news-details-text11 {
    color: rgb(255, 255, 255);
    height: auto;
    opacity: 0.8;
    font-size: 14px;
    max-width: 1002px;
    font-style: Medium;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 500;
    line-height: 25.2px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-top-navigation-bar {
    gap: 10px;
    display: flex;
    padding: 10px 30px 25px;
    overflow: hidden;
    align-self: stretch;
    align-items: center;
    flex-shrink: 0;
    justify-content: space-between;
}

.news-details-top-navigation-bar-left-logo {
    gap: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.news-details-logo {
    width: 250px;
    height: 64px;
}

.news-details-top-navigation-bar-right-links {
    gap: 38px;
    display: flex;
    opacity: 0.8;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.news-details-text12 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 18px;
    font-style: Bold;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 25.200000762939453px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text14 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 18px;
    font-style: Bold;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 25.200000762939453px;
    font-stretch: normal;
    text-decoration: none;
}

.news-details-text16 {
    color: rgba(255, 255, 255, 1);
    height: auto;
    font-size: 18px;
    font-style: Bold;
    text-align: left;
    font-family: Noto Sans SC;
    font-weight: 700;
    line-height: 25.200000762939453px;
    font-stretch: normal;
    text-decoration: none;
}

@media (max-width: 991px) {
    .news-details-news-container {
        position: relative;
    }

    .news-details-frame13 {
        padding-left: 30px;
        padding-right: 30px;
    }

    .news-details-text {
        color: rgb(255, 255, 255);
        width: 100%;
        font-size: 24px;
        font-family: Noto Sans SC;
        font-weight: 700;
        line-height: 33.6px;
        text-decoration: none;
    }

    .news-details-text01 {
        color: rgb(255, 255, 255);
        width: 100%;
        font-size: 16px;
        font-family: Noto Sans SC;
        font-weight: 700;
        line-height: 22.4px;
        text-decoration: none;
    }
}

@media (max-width: 767px) {
    .news-details-news-container {
        width: 96%;
    }

    .news-details img {
        max-width: 100%;
    }

    .news-details-frame13 {
        padding-left: 10px;
        padding-right: 10px;
    }

    .news-details-text {
        font-size: 18px;
        line-height: 1.6;
    }

    .news-details-text01 {
        font-size: 14px;
        line-height: 1.6;
    }

    .news-details-sample-img1 {
        height: auto;
    }

    .news-details-placeholder3 {
        height: auto;
    }

    .news-details-logo {
        width: auto;
        height: 2rem;
    }

    .news-details-text12 {
        font-size: 16px;
    }

    .news-details-text14 {
        font-size: 16px;
    }

    .news-details-text16 {
        font-size: 16px;
    }
}</style>
  
<template>
  <div class="about-container" id="page-container">
    <navbar-interactive
            rootClassName="navbar-interactive-root-class-name1"
          ></navbar-interactive>
    <div class="about-frame">
      <div class="about-page-content">
        <div class="about-hero-image">

          <div class="about-logo-description">
            <img alt="image" src="/huahua-300w.png" class="about-image" />
            <div class="about-description">
              <span class="about-text">关于我们</span>
              <span class="about-text01">多重基因，多栖效应<br>从事艺术也研究科学</span>
            </div>
          </div>
          <div class="about-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="about-bottom-arrow"
            />
          </div>
        </div>

        <div class="about-frame83">
          <img
            alt="Placeholder20322"
            src="Placeholder 2 (2).png"
            class="about-placeholder2"
          />
          <div class="about-frame6">
            <div class="about-frame80">
              <img
                alt="SVG0307"
                src="/external/svg0307-2pmw.svg"
                class="about-svg"
              />
              <span class="about-text02"><span>主要业务</span></span>
              <span class="about-text04">
                <span>
                  <span>2D话画：更懂中国文化和东方审美的AI作图平台</span>
                  <br />
                  <span>3D创易：三维数字资产聚合平台、图像升维研究</span>
                  <br />
                  <span>AIGC创意美学教育：青少年儿童AI普及教育</span>
                  <br />
                  <span>沉浸互动解决方案：互动游戏、全息视听、数字展、光影夜游</span>
                </span>
              </span>
            </div>
            <div class="about-frame81">
              <img
                alt="SVG0327"
                src="/external/svg0327-5809.svg"
                class="about-svg1"
              />
              <span class="about-text11"><span>AIGC企业级服务</span></span>
              <span class="about-text13">
                <span>私有化部署，API服务</span>
                <br />
                <span>AIGC企业品牌全案设计服务</span>
              </span>
            </div>
            <div class="about-frame82">
              <img
                alt="SVG0319"
                src="/external/svg0319-n3tj.svg"
                class="about-svg2"
              />
              <span class="about-text15"><span>联系我们</span></span>
              <span class="about-text17">
                <span>
                  <span>电话：18612990710</span>
                  <br />
                  <span>邮箱：im@solart.pro</span>
                  <br />
                  <span>微信：solarwoo</span>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div class="about-top-navigation-bar">
          <div class="about-top-navigation-bar-left-logo">
            <img alt="logo6457" src="/logo%201-1500h.png" class="about-logo" />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BottomBar from '../components/bottomBar.vue'
import NavbarInteractive from '../components/navbar-interactive'

export default {
  name: 'About',
  components: {
    NavbarInteractive,
    BottomBar,
  },
  metaInfo: {
    title: '关于我们 - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: '关于我们 - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.about-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.about-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.about-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.about-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/Placeholder (1).png");
}
.about-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-image {
  width: 20vw;
  height: auto;
  position: absolute;
  max-width: 400px;
  object-fit: cover;
}
.about-description {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.about-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
.about-text01 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 50.4px;
  font-stretch: normal;
  text-decoration: none;
  letter-spacing: 15px;

}
.about-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.about-bottom-arrow {
  width: 16px;
  height: 8px;
}
.about-frame7 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-self: stretch;
  align-items: flex-start;
  flex-direction: column;
  align-items: center;
}
.about-video {
  width: 85vw;
}
.about-frame83 {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
  background-color: rgba(0, 0, 0, 1);
}
.about-placeholder2 {
  width: 30vw;
  height: 300px;
  object-fit: cover;
}
.about-frame6 {
  width: 70vw;
  display: flex;
  align-items: flex-start;
  flex-shrink: 0;
  margin-left: auto;
  padding-top: 0px;
  margin-right: auto;
  padding-left: auto;
  padding-right: auto;
  padding-bottom: 0px;
  justify-content: space-between;
  padding: 0 5vw;
}
@media (max-width: 738px) {
  .about-frame6 {
    flex-direction: column;
    gap:60px;
    align-items: center;
  }
}
  
.about-frame80 {
  gap: 21px;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.about-svg {
  width: 27px;
  height: 22px;
}
.about-text02 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.about-text04 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 14px;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.about-frame81 {
  gap: 23px;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.about-svg1 {
  width: 25px;
  height: 25px;
}
.about-text11 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.about-text13 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 14px;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.about-frame82 {
  gap: 21px;
  display: flex;
  overflow: hidden;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  flex-direction: column;
}
.about-svg2 {
  width: 24px;
  height: 24px;
}
.about-text15 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 24px;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.about-text17 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 14px;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 24px;
  font-stretch: normal;
  text-decoration: none;
}
.about-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.about-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.about-logo {
  width: 250px;
  height: 64px;
}
.about-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.about-text24 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.about-text26 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.about-text28 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .about-frame83 {
    padding: 40px 0;
    justify-content: space-between;
    flex-direction: column;
  }
  .about-frame6 {
    width: 100vw;

  }
  .about-placeholder2 {
    display: none;
  }
}
@media(max-width: 767px) {
  .about-image {
    min-width: 200px;
  }
  .about-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  @media(max-width: 430px) {
    .about-text01 {
    font-size: 1.25rem;
    letter-spacing: normal;
  }
  }
  .about-logo {
    width: auto;
    height: 2rem;
  }
  .about-text24 {
    font-size: 16px;
  }
  .about-text26 {
    font-size: 16px;
  }
  .about-text28 {
    font-size: 16px;
  }
}
</style>

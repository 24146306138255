<template>
  <div class="huahu-kids-container" id="page-container">
    <navbar-interactive rootClassName="navbar-interactive-root-class-name3"></navbar-interactive>
    <div class="huahu-kids-frame">
      <div class="huahu-kids-page-content">
        <div class="huahu-kids-hero-image">
          <div class="huahu-kids-logo-description">
            <div class="huahu-kids-description">
              <img
                alt="image"
                src="/logos/Solart3d.svg"
                class="huahu-kids-image"
              />
              <div class="title_container">
                <span class="huahu-kids-text">沉浸式数字互动解决方案</span>
                <span class="huahu-kids-text01">
                  AIGC·科学与艺术创新融合
                </span>
              </div>
            </div>
          </div>
          <div class="huahu-kids-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="huahu-kids-bottom-arrow"
            />
          </div>
        </div>
        <div>
          <a href="https://store.steampowered.com/app/2568060/Interstellar_Shore_VR_I_LIVE/" target="_blank">
            <product-introduction-cardv2
              image_src="/Immersive1.png?v=1"
              mainTitle="全息视听光学研究"
              subTitle="以裸眼3D全息立体硬件设备为依托，结合自研创新算法，进行声音和光学科学研究，突破视听行业技术壁垒，打造令人惊叹的视听体验。裸眼3D全息立体硬件设备，拥有超大视角、高清晰的3D立体效果，无需任何辅助，真裸眼可见。通过这项服务，可为广告、娱乐、文创等行业带来更多可能性，让品牌营销更加震撼和引人入胜，助力企业吸引目标受众，塑造深入人心的独特形象。"
            ></product-introduction-cardv2>
          </a>
          <product-introduction-cardv2
            reverse
            image_src="Immersive2.png"
            mainTitle="Resort V 2027 AIGC游戏"
            subTitle="一款开放世界的元宇宙游戏，由我们的联合创始人刘懿华创作和监制。这款游戏巧妙地运用了大量先进的人工智能生成内容（AIGC）技术，而且还让AI技术与剧情完美融合，为剧情增添了无限可能。我们致力于为每位玩家打造一个独一无二、与众不同的个性化游戏体验，确保他们在这个充满创意和可能性的世界中，每一次探索和互动都充满惊喜和新鲜感，每个故事都是专属于玩家自己的独特旅程。"
          ></product-introduction-cardv2>
          <product-introduction-cardv2
              image_src="/Immersive3.png"
              mainTitle="沉浸式数字展"
              subTitle="SOLART TECH 素鳞科技亮相2023年“北京设计与艺术博览会”—“数字文旅、科技之美”展览单元，带来数字智能化新世代美学体验空间。联合多个数字创意团队与艺术家，以AI人工智能技术为引，以SOLART裸眼3D全息、体感互动、Mapping投影、AR增强现实等多种新载体为媒介，融合多种数字艺术创作手法，创新多元艺术表现形式，打造出一场极具未来感的艺术体验之旅。 TECH的基础设施上完成了生产管线的迭代和重要的内容创作，这也是SOLART TECH是生态基础的最好证明。以Cosmico Tech为例，Jieer和Shawn的将话画AIGC、GPT、Claude等全球顶尖的AI技术融合并迭代出全新的AI Native生产管线，代领全球领先的院校和实验室完成了《星际岸VR》交互叙事影片，是全球最早应用AI技术迭代生产过程完成的完整工业级产品之一。"
            ></product-introduction-cardv2>
          <product-introduction-cardv2
            reverse
            image_src="Immersive4.png"
            mainTitle="沉浸式光影夜游"
            subTitle="与“深林觅境”联合打造“沉浸式光影夜游”数字科技艺术项目。通过数字多媒体和互动装置，融合自然、文化和科技，以创新多元化艺术表现形式，为参观者带来沉浸式光影游览体验。您将置身于一个由音乐、灯光、投影和最新AIGC科技手段编织奇幻“森林秘境”中，唤醒更深层次的感官意识，沉浸式体验大自然的神秘与美丽，感受光影交织的独特魅力。<br/>体验地点：北京丰台区南四环中路235号"
          ></product-introduction-cardv2>
          <product-introduction-cardv2
            mainTitle="AI魔法画像机“话画HUB”创意美学画像输出"
            subTitle="以SOLART TECH自主研发的AI艺术视觉创作技术工具为核心程序，现场将自己的照片瞬时创作成艺术画像，实现远优于传统滤镜的创意美学效果输出。2023年首次亮相SOLART  TECH参与打造的泰国·曼谷“莫奈与他的朋友们”沉浸式光影艺术展，展出仅两周便已累计过万游客体验拍照，引起国内外广泛关注。"
            image_src="/Placeholder_huahua.png"
          ></product-introduction-cardv2>
        </div>
        <div class="huahu-kids-top-navigation-bar">
          <div class="huahu-kids-top-navigation-bar-left-logo">
            <img
              alt="logo6457"
              src="/logo%201-1500h.png"
              class="huahu-kids-logo"
            />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import AppBtndefault from '../components/btndefault'
import ProductIntroductionCardv2 from '../components/product-introduction-cardv2'
import productCarousel from '../components/productCarousel.vue'
import BottomBar from '../components/bottomBar.vue'

export default {
  name: 'immersiveDigital',
  components: {
    NavbarInteractive,
    AppBtndefault,
    ProductIntroductionCardv2,
    productCarousel,
    BottomBar
  },
  data(){
    BottomBar
    return{}
  },
  metaInfo: {
    title: '沉浸式互动解决方案 - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: '沉浸式互动解决方案 - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.huahu-kids-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.huahu-kids-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/immersiveDigital.png");
  background-position-x: 32%;
}
.huahu-kids-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-description {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.huahu-kids-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text01 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  letter-spacing: 12px;
  text-decoration: none;
}
.title_container{
  display: flex; 
  flex-direction: column;
  gap: 18px;
  max-width: 95vw;
}
@media (max-width: 956px) {
  .huahu-kids-text {
    font-size: 48px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}
@media (max-width: 541px) {
  .huahu-kids-text {
    font-size: 30px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

@media (max-width: 374px) {
  .huahu-kids-text {
    font-size: 26px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

.huahu-kids-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-bottom-arrow {
  width: 16px;
  height: 8px;
}
.huahu-kids-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.huahu-kids-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-logo {
  width: 250px;
  height: 64px;
}
.huahu-kids-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.huahu-kids-text05 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text07 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text09 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .huahu-kids-text {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    text-decoration: none;
  }
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-logo {
    width: auto;
    height: 2rem;
  }
  .huahu-kids-text05 {
    font-size: 16px;
  }
  .huahu-kids-text07 {
    font-size: 16px;
  }
  .huahu-kids-text09 {
    font-size: 16px;
  }
}
</style>

import { render, staticRenderFns } from "./about.vue?vue&type=template&id=54a5bde7&scoped=true&"
import script from "./about.vue?vue&type=script&lang=js&"
export * from "./about.vue?vue&type=script&lang=js&"
import style0 from "./about.vue?vue&type=style&index=0&id=54a5bde7&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a5bde7",
  null
  
)

export default component.exports
<template>
  <div class="news-card-news-card" @click="redirect">
    <img :src="newsData.thumb ? newsData.thumb : '/placeholder 1 (1)-1500w.png'" :alt="mainTitle" class="news-card-cover" />
    <div class="news-card-contentcontainer">
      <div class="news-card-subtitle">
        <span class="news-card-text">{{ subTitle }}</span>
        <underline-block></underline-block>
      </div>
      <span class="news-card-text1">{{ mainTitle }}</span>
      <!-- <span class="news-card-text2">{{ newsData.excerpt }}</span> -->
      <span class="news-card-text3">{{ newsData.time }}</span>
    </div>
  </div>
</template>

<script>
import he from "he";

export default {
  name: "NewsCard",
  components: {
    UnderlineBlock: () => import("./underlineBlock.vue"),
  },
  methods: {
    redirect: function () {
      this.$router.push({ name: "NewsDetails", params: { id: this.newsID } });
    },
  },
  props: {
    SVG1_src: {
      type: String,
      default: "/external/svg14536-4zx9.svg",
    },
    SVG1_alt: {
      type: String,
      default: "SVG14536",
    },
    subTitle: {
      type: String,
      default: "公司动态",
    },
    mainTitle: {
      type: String,
      default: "标题示例标题示例标题示例标题示例标题示例标题示例",
    },

    timeStamp: {
      type: Number,
      default: 0,
    },
    newsID: {
      type: Number,
      default: 0,
    },
    newsListLength: {
      type: Number,
      default: 0,
    },
    newsData: null,
  },
};
</script>

<style scoped>
.news-card-news-card {
  cursor: pointer;
  max-width: 100vw;
  width: 370px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-items: center;
  flex-shrink: 0;
  border-radius: 16px;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 1);
}

.news-card-cover {
  width: 100%;
  height: 206px;
  border-radius: 16px 16px 0 0;
  object-fit: cover;
}
.news-card-contentcontainer {
  width: 100%;
  padding: 30px;
  gap: 12px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  flex: 1;
}
.news-card-subtitle {
  width: 100%;
  gap: 5px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.news-card-text {
  color: rgb(255, 255, 255);
  width: 146px;
  height: auto;
  font-size: 14px;
  font-style: Regular;
  text-align: justified;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 19.6px;
  font-stretch: normal;
  text-decoration: none;
}
.news-card-svg1 {
  width: 313px;
  height: 1px;
  object-fit: cover;
}
.news-card-text1 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 18px;
  font-style: normal;
  text-align: justify;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.2px;
  font-stretch: normal;
  text-decoration: none;
}
.news-card-text2 {
  color: rgb(255, 255, 255);
  width: 315px;
  height: auto;
  opacity: 0.8;
  font-size: 14px;
  text-align: justify;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 19.6px;
  font-stretch: normal;
  text-decoration: none;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-card-text3 {
  color: rgb(255, 255, 255);
  width: 146px;
  height: auto;
  opacity: 0.6;
  font-size: 14px;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 19.6px;
  font-stretch: normal;
  text-decoration: none;
  margin-top: auto;
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import HUAHUA from './views/huahua'
import About from './views/about'
import SOLART3D from './views/solart-3d'
import HUAHUAKids from './views/huahua-kids'
import Home from './views/home'
import NotFound from './views/not-found'
import News from './views/news'
import NewsDetails from './views/news-details'
import PrivatePolicy from './views/private-policy'
import ImmersiveDigital from './views/immersive-digital'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  mode: 'history',
  routes: [
    {
      name: 'HUAHUA',
      path: '/huahua',
      component: HUAHUA,
    },
    {
      name: 'About',
      path: '/about',
      component: About,
    },
    {
      name: 'SOLART-3D',
      path: '/3dchuangyi',
      component: SOLART3D,
    },
    {
      name: 'HUAHUA-kids',
      path: '/huahuakids',
      component: HUAHUAKids,
    },
    {
      name: 'Immersive-Digital',
      path: '/immersive',
      component: ImmersiveDigital,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
    {
      name: 'News',
      path: '/news',
      component: News,
    },
    {
      name: 'NewsDetails',
      path: '/news/:id',
      component: NewsDetails,
    },
    {
      name: 'PrivatePolicy',
      path: '/private-policy',
      component: PrivatePolicy,
    },
    {
      name: 'NotFound',
      path: '**',
      component: NotFound,
    },
  ],
})

<template>
  <div class="product-introduction-cardv2-first" :class="{reverse: reverse}">
    <img
      :alt="image_alt"
      :src="image_src"
      class="product-introduction-cardv2-image"
    />
    <div data-aos="fade-up-right" class="product-introduction-cardv2-content">
      <div class="product-introduction-cardv2-list">
        <span class="product-introduction-cardv2-text">{{ mainTitle }}</span>
        <span class="product-introduction-cardv2-text1">
          <span v-html="subTitle"></span>
          <br />
          <br />
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductIntroductionCardv2',
  props: {
    image_src: {
      type: String,
      default: '/placeholder%201%20(1)-1500w.png',
    },
    mainTitle: {
      type: String,
      default: '示例标题',
    },
    subTitle: {
      type: String,
      default: '示例内容',
    },
    image_alt: {
      type: String,
      default: 'image',
    },
    reverse: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
.product-introduction-cardv2-first {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: row;
  background-color: #000000;
}
.product-introduction-cardv2-image {
  width: 50%;
  height: 400px;
  object-fit: cover;
  aspect-ratio: 1;

}
.product-introduction-cardv2-content {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
}
.product-introduction-cardv2-list {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.product-introduction-cardv2-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 36px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 50.4px;
  font-stretch: normal;
  text-decoration: none;
}
.product-introduction-cardv2-text1 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 16px;
  font-style: 65 Medium;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 500;
  line-height: 26px;
  font-stretch: normal;
  text-decoration: none;
}

.reverse{
  flex-direction: row-reverse;
}

@media(max-width: 991px) {
  .product-introduction-cardv2-first {
    flex-direction: column;
  }
  .product-introduction-cardv2-image {
    flex: 0 0 auto;
    width: 100%;
  }
  .product-introduction-cardv2-content {
    padding-top: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
}
@media(max-width: 767px) {
  .product-introduction-cardv2-content {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 479px) {
  .product-introduction-cardv2-list {
    gap: var(--dl-space-space-unit);
  }
}
</style>

<template>
  <div class="name-card0-name-card0">
    <div class="name-card0-avatar">
      <img
        alt="Ellipse1I644"
        :src="namecard_avatar"
        class="name-card0-ellipse1"
      />
    </div>
    <div class="name-card0-namedescription">
      <span class="name-card0-text">{{ name }}</span>
      <span class="name-card0-text1">{{ description }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NameCard0',
  props: {
    name: {
      type: String,
      default: '张三',
    },
    namecard_avatar: {
      type: String,
      default: '/user-60-200h.png',
    },
    description: {
      type: String,
      default: '描述如下，团队作用',
    },
  },
}
</script>

<style scoped>
.name-card0-name-card0 {
  gap: 47px;
  display: flex;
  position: relative;
  align-items: center;
  width:300px;
}
@media screen and (max-width: 514px) {
  .name-card0-name-card0 {
    flex-direction: column;
  }
}
.name-card0-avatar {
  gap: 10px;
  display: flex;
  align-items: center;
}
.name-card0-avatar img {
  border-radius: 50%;
  overflow: hidden;
}
.name-card0-ellipse1 {
  width: 80px;
  height: 80px;
}
.name-card0-namedescription {
  gap: 16px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  align-items:center;
}
.name-card0-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.6px;
  font-stretch: normal;
  text-decoration: none;
}
.name-card0-text1 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 14px;
  font-style: 85 Bold;
  text-align: left;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 19.6px;
  font-stretch: normal;
  text-decoration: none;
}
</style>

<template>
  <div class="news-container" id="page-container">
    <navbar-interactive
      rootClassName="navbar-interactive-root-class-name6"
      v-bind:defaultBlack="true"
    ></navbar-interactive>
    <div class="news-frame">
      <div class="news-page-content">
        <div class="news-hero-image"></div>
        <div class="news-news-container">
          <div class="news-frame93">
            <span class="news-text">新闻</span>
            <span class="news-text1">了解solart最新动态</span>
          </div>
          <div class="news-frame10">
            <span class="news-text2" v-bind:class="{ hidden: success }">加载失败</span>
            <news-card
              v-for="item in newsList"
              v-bind:key="item.id"
              v-bind:newsID="item.id"
              v-bind:mainTitle="item.title"
              v-bind:newsData="item"
            ></news-card>
          </div>
        </div>
        <div class="news-top-navigation-bar">
          <div class="news-top-navigation-bar-left-logo">
            <img alt="logo6457" src="/logo%201-1500h.png" class="news-logo" />
          </div>
          <BottomBar />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from "../components/navbar-interactive";
import NewsCard from "../components/news-card";
import BottomBar from "../components/bottomBar.vue";

export default {
  name: "News",
  components: {
    NavbarInteractive,
    NewsCard,
    BottomBar,
  },
  data() {
    return {
      newsList: null,
      imgList: [],
      success: true,
    };
  },
  created() {
    const vm = this;
    var requestOptions = {
      method: "GET",
      redirect: "follow",
    };
    fetch("https://solart.pro/portal/list/?cate=1", requestOptions)
      .then((response) => response.json()) // Use response.json() to parse the JSON
      .then((data) => {
        console.log(data);
        this.newsList = data.data;
      })
      .catch((error) => {
        console.error(error);
        vm.success = false;
      });
    //when clicking on the card, it will redirect to the news page
    // this.$router.push({ name: 'news-detail', params: { id: this.newsID } })
  },
  metaInfo: {
    title: "新闻 - SOLART素鳞科技",
    meta: [
      {
        property: "og:title",
        content: "新闻 - SOLART素鳞科技",
      },
    ],
  },
};
</script>

<style scoped>
.news-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.news-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.news-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
}
.news-hero-image {
  gap: 10px;
  height: auto;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-color: #1a1a1a;
}
.news-news-container {
  gap: 20px;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 85vh;
}
.news-frame93 {
  margin-top: 16px;
  gap: 12px;
  width: 100%;
  height: 98px;
  display: flex;
  overflow: hidden;
  position: relative;
  align-self: center;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: flex-start;
}
.news-text {
  color: rgb(255, 255, 255);
  width: 74px;
  height: auto;
  font-size: 36px;
  font-style: Regular;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 400;
  line-height: 50.4px;
  font-stretch: normal;
  text-decoration: none;
}
.news-text1 {
  letter-spacing: 0.8em;

  color: rgb(255, 255, 255);
  width: auto;
  height: auto;
  opacity: 0.8;
  font-size: 18px;
  font-style: Bold;
  text-align: center;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.2px;
  font-stretch: normal;
  text-decoration: none;
}
@media (max-width: 354px) {
  .news-text1 {
    letter-spacing: normal;
  }
}
.news-frame10 {
  gap: 37px;
  width: 84%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(370px, 1fr));
}
.news-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.news-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.news-logo {
  width: 250px;
  height: 64px;
}
.news-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.8;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.news-text2 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.news-text4 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.news-text6 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media (max-width: 767px) {
  .news-logo {
    width: auto;
    height: 2rem;
  }
  .news-text2 {
    font-size: 16px;
  }
  .news-text4 {
    font-size: 16px;
  }
  .news-text6 {
    font-size: 16px;
  }
  .news-frame10 {
    width: 90%;
    display: flex;
    flex-direction: column;
  }
  :deep(.news-card-news-card) {
    width: 100%;
  }
}
</style>

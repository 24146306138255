<template>
  <div class="solart3d-container" id="page-container">
    <navbar-interactive
            rootClassName="navbar-interactive-root-class-name5"
          ></navbar-interactive>
    <div class="solart3d-frame">
      <div class="solart3d-page-content">
        <div class="solart3d-hero-image">

          <div class="solart3d-logo-description">
            <div class="solart3d-description">
              <img
                alt="image"
                src="/logos/Solart3d.svg"
                class="solart3d-image"
              />
              <div class="title_container">
                <span class="solart3d-text">ARTOL 3D创易</span>
              <span class="solart3d-text1">一步到位·构筑你的虚拟世界</span>
              </div>

              <a
                href="http://3d.solart.pro"
                target="_blank"
                rel="noreferrer noopener"
                class="solart3d-link"
              >
                <app-btndefault
                  text="进入官网"
                  rootClassName="btndefault-root-class-name2"
                  class="solart3d-component1"
                ></app-btndefault>
              </a>
            </div>
          </div>
          <div class="solart3d-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="solart3d-bottom-arrow"
            />
          </div>
        </div>
        <div>
          <product-introduction-cardv2
            image_src="/Placeholder 1 (8).png"
            mainTitle="一站式3D模型数据库平台"
            subTitle="创易3D为元宇宙建设者以及不同行业的数字可视化应用专业人士提供可持续和可靠的基础设施，和3D数字资产领域的生态系统。涵盖所有3D视觉方向的生产阶段的组件资产，包括游戏、动画、电影、虚拟表演、虚拟直播、元宇宙建设者以及其他专业行业，如航空航天、医疗、建筑等。
            创作者们能够在最佳模型之上构建和定制，更轻松、更快速地创建3D世界。创易也是大型3D模型机器学习数据库，以满足3DAIGC人工智能建模的未来需求和目标。"          
          ></product-introduction-cardv2>
          <product-introduction-cardv2
            reverse
            image_src="/3dchuangyi2.png"
            mainTitle="图像升维研究：二维影像转制3D全息立体影像"
            subTitle="SOLART图像升维研究，以裸眼3D全息立体硬件设备为基础，探索二维画面升维三维光学画面的算法研究，将2D画面转换为3D光学图像，也可实现将手机、相机、无人机等拍摄设备采集的人物、场景的多角度画面还原为3D光学模型，直接在裸眼3D全息立体硬件设备中显示。"          
          ></product-introduction-cardv2>
        </div>
        <span class="home-text06 hidden"><span>产品特色</span></span>
        <product-carousel class="hidden" :image_src="caresouleImgs"></product-carousel>
        <div class="solart3d-top-navigation-bar">
          <div class="solart3d-top-navigation-bar-left-logo">
            <img alt="logo6457" src="/logo%201-1500h.png" class="solart3d-logo" />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import AppBtndefault from '../components/btndefault'
import ProductIntroductionCardv2 from '../components/product-introduction-cardv2'
import productCarousel from '../components/productCarousel.vue'
import BottomBar from '../components/bottomBar.vue'

export default {
  name: 'SOLART3D',
  components: {
    NavbarInteractive,
    AppBtndefault,
    ProductIntroductionCardv2,
    productCarousel,
    BottomBar,

  },
  data(){
    return{
      caresouleImgs: [
        '/exampleSlide1.png',
        '/exampleSlide2.png',
        '/exampleSlide3.png',
      ],
    }
  },
  metaInfo: {
    title: 'ARTOL 3D创易 - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'ARTOL 3D创易 - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.solart3d-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.solart3d-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.solart3d-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/solart3d.png");
  background-position: center;
}
.solart3d-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.solart3d-description {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.solart3d-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.title_container{
  display: flex; 
  flex-direction: column;
  gap: 18px;
}
.solart3d-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-text1 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  letter-spacing: 12px;
  text-decoration: none;
}

@media (max-width: 606px) {
  .solart3d-text {
    font-size: 32px !important;
    line-height: 38px !important;
  }
  .solart3d-text1{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}
}
.solart3d-link {
  display: contents;
}
.solart3d-component1 {
  text-decoration: none;
}
.solart3d-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.solart3d-bottom-arrow {
  width: 16px;
  height: 8px;
}
.solart3d-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.solart3d-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.solart3d-logo {
  width: 250px;
  height: 64px;
}
.solart3d-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.solart3d-text2 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-text4 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.solart3d-text6 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .solart3d-text {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    text-decoration: none;
  }
  .solart3d-text1 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .solart3d-text1 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .solart3d-logo {
    width: auto;
    height: 2rem;
  }
  .solart3d-text2 {
    font-size: 16px;
  }
  .solart3d-text4 {
    font-size: 16px;
  }
  .solart3d-text6 {
    font-size: 16px;
  }
}
</style>

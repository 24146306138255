<template>
  <div class="huahu-kids-container" id="page-container">
    <navbar-interactive
            rootClassName="navbar-interactive-root-class-name3"
          ></navbar-interactive>
    <div class="huahu-kids-frame">
      <div class="huahu-kids-page-content">
        <div class="huahu-kids-hero-image">

          <div class="huahu-kids-logo-description">
            <div class="huahu-kids-description">
              <img
                alt="image"
                src="/logos/huahuakids.svg"
                class="huahu-kids-image"
              />
              <div class="title_container">
                <span class="huahu-kids-text">AIGC创意美学教育</span>
                <span class="huahu-kids-text01">
                  青少年AIGC数字创意普及教育
                </span>
              </div>
              <!-- <app-btndefault
                :isDisabled = "true"
                text="敬请期待"
                rootClassName="btndefault-root-class-name"
              ></app-btndefault> -->
            </div>
          </div>
          <div class="huahu-kids-mottos">
            <img
              alt="bottomArrow6397"
              src="/bottomarrow.svg"
              class="huahu-kids-bottom-arrow"
            />
          </div>
        </div>
        <div>
          <product-introduction-cardv2
            image_src="/Placeholder 1 (9).png"
            mainTitle="HUAHUA Kids话画少年：专为儿童和青少年打造的AIGC美学教育应用"
            subTitle="通过让孩子自定义图像的风格、颜色、角色等元素,个性化的生成图像，培养孩子的审美和决策能力。
  通过中英文的语音交互,让孩子用说话的方式与话画少年互动,在练习语言的同时，创作出独一无二的专属画作,更具趣味感。
  通过设置三重过滤模型,自动检测文本描述中的敏感词汇,并过滤屏蔽不适宜图像，使学习更放心。
  通过融入常识科普、创造力培育等知识点，激发儿童的想象力和创造力,陪伴他们健康快乐地成长。这可以让娱乐性创作兼具启发意义
  我们期待每个孩子都获得不一样的体验，成为未来世界的Prompt Engineer。"
          ></product-introduction-cardv2>
          <product-introduction-cardv2
            reverse
            image_src="Placeholder_huahuakids.png"
            mainTitle="话画AI创意工坊：涂鸦瞬时生成“艺术大作”"
            subTitle="培养AI创新思维，打造AI时代新美育，专为青少年儿童定制的线下数字艺术启蒙体验课。随手涂鸦，上传草图，即刻AI生成画风成熟的“艺术大作”：“莫奈印象派”、“中式水墨风”、“写意淡彩”……多种AI风格模型持续更新。
            <br/>除AI数字艺术画作外，文创产品制作体验，让小朋友“从0到1”将自己的作品制作成“独一无二”的专属文创产品。“审美力” 、“想象力”、 “创造力” 、 “跨学科思维”，以兴趣为引，培养孩子多维度能力全面发展。
            <br/>线下体验地点：
            <br/>①话画AI创意工坊：新广州艺博院1F文创空间
            <br/>②AI艺术创意思维——研学营：广州艺术博院（广州美术馆）新馆"
          ></product-introduction-cardv2>
        </div>
        <!-- <span class="home-text06 hidden"><span>产品特色</span></span>
        <product-carousel class="hidden" :image_src="caresouleImgs"></product-carousel> -->
        <div class="huahu-kids-top-navigation-bar">
          <div class="huahu-kids-top-navigation-bar-left-logo">
            <img
              alt="logo6457"
              src="/logo%201-1500h.png"
              class="huahu-kids-logo"
            />
          </div>
          <bottom-bar></bottom-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NavbarInteractive from '../components/navbar-interactive'
import AppBtndefault from '../components/btndefault'
import ProductIntroductionCardv2 from '../components/product-introduction-cardv2'
import productCarousel from '../components/productCarousel.vue'
import BottomBar from '../components/bottomBar.vue'

export default {
  name: 'HUAHUAKids',
  components: {
    NavbarInteractive,
    AppBtndefault,
    ProductIntroductionCardv2,
    productCarousel,
    BottomBar
  },
  data(){
    BottomBar
    return{
      caresouleImgs: [
        '/exampleSlide1.png',
        '/exampleSlide2.png',
        '/exampleSlide3.png',
      ],
    }
  },
  metaInfo: {
    title: 'HUAHUA Kids话画少年  - SOLART素鳞科技',
    meta: [
      {
        property: 'og:title',
        content: 'HUAHUA Kids话画少年  - SOLART素鳞科技',
      },
    ],
  },
}
</script>

<style scoped>
.home-text06 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  opacity: 0.80;
  font-size: 24px;
  font-style: 85 Bold;
  text-align: justified;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 33.599998474121094px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-frame {
  gap: 10px;
  width: 100%;
  height: auto;
  display: flex;
  overflow: hidden;
  align-items: flex-start;
  flex-shrink: 0;
  background-color: rgba(26, 26, 26, 1);
}
.huahu-kids-page-content {
  gap: 39px;
  width: 100%;
  display: flex;
  flex-grow: 1;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  min-height: 100vh;

}
.huahu-kids-hero-image {
  gap: 10px;
  height: 900px;
  display: flex;
  padding: 22px 0;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  background-size: cover;
  justify-content: space-between;
  background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0.00%,rgba(0, 0, 0, 0.4) 100.00%),url("/huahuakids.png");
  background-position-x: 32%;
}
.huahu-kids-logo-description {
  gap: 40px;
  display: flex;
  overflow: hidden;
  flex-grow: 1;
  align-self: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-description {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-image {
  width: 160px;
  height: 160px;
  object-fit: cover;
}
.huahu-kids-text {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 64px;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 64px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text01 {
  color: rgb(255, 255, 255);
  height: auto;
  font-size: 2rem;
  font-style: 85 Bold;
  text-align: center;
  font-family: Alibaba PuHuiTi 2.0;
  font-weight: 700;
  line-height: 36px;
  font-stretch: normal;
  letter-spacing: 12px;
  text-decoration: none;
}
.title_container{
  display: flex; 
  flex-direction: column;
  gap: 18px;
  max-width: 95vw;
}
@media (max-width: 956px) {
  .huahu-kids-text {
    font-size: 48px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}
@media (max-width: 541px) {
  .huahu-kids-text {
    font-size: 30px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

@media (max-width: 374px) {
  .huahu-kids-text {
    font-size: 26px !important;
    line-height: 38px !important;
  }
  .huahu-kids-text01{
    font-size: 20px !important;
    line-height: 6px;
    letter-spacing:normal !important;
  }
  .title_container{
  display: flex; 
  flex-direction: column;
  gap: 2px;
}

}

.huahu-kids-mottos {
  gap: 9px;
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
}
.huahu-kids-bottom-arrow {
  width: 16px;
  height: 8px;
}
.huahu-kids-top-navigation-bar {
  gap: 10px;
  display: flex;
  padding: 10px 30px 25px;
  overflow: hidden;
  align-self: stretch;
  align-items: center;
  flex-shrink: 0;
  justify-content: space-between;
}
.huahu-kids-top-navigation-bar-left-logo {
  gap: 10px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.huahu-kids-logo {
  width: 250px;
  height: 64px;
}
.huahu-kids-top-navigation-bar-right-links {
  gap: 38px;
  display: flex;
  opacity: 0.80;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.huahu-kids-text05 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text07 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
.huahu-kids-text09 {
  color: rgba(255, 255, 255, 1);
  height: auto;
  font-size: 18px;
  font-style: Bold;
  text-align: left;
  font-family: Noto Sans SC;
  font-weight: 700;
  line-height: 25.200000762939453px;
  font-stretch: normal;
  text-decoration: none;
}
@media(max-width: 991px) {
  .huahu-kids-text {
    color: rgb(255, 255, 255);
    font-size: 64px;
    font-weight: 700;
    line-height: 64px;
    text-decoration: none;
  }
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 12px;
    text-decoration: none;
  }
}
@media(max-width: 767px) {
  .huahu-kids-text01 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text02 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text03 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-text04 {
    color: rgb(255, 255, 255);
    font-size: 2rem;
    font-style: normal;
    font-weight: 400;
    line-height: 36px;
    text-decoration: none;
  }
  .huahu-kids-logo {
    width: auto;
    height: 2rem;
  }
  .huahu-kids-text05 {
    font-size: 16px;
  }
  .huahu-kids-text07 {
    font-size: 16px;
  }
  .huahu-kids-text09 {
    font-size: 16px;
  }
}
</style>
